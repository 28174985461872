exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-nasopharyngeal-cancer-jsx": () => import("./../../../src/pages/about-nasopharyngeal-cancer.jsx" /* webpackChunkName: "component---src-pages-about-nasopharyngeal-cancer-jsx" */),
  "component---src-pages-asco-jsx": () => import("./../../../src/pages/asco.jsx" /* webpackChunkName: "component---src-pages-asco-jsx" */),
  "component---src-pages-conference-jsx": () => import("./../../../src/pages/conference.jsx" /* webpackChunkName: "component---src-pages-conference-jsx" */),
  "component---src-pages-diagnosis-and-treatment-jsx": () => import("./../../../src/pages/diagnosis-and-treatment.jsx" /* webpackChunkName: "component---src-pages-diagnosis-and-treatment-jsx" */),
  "component---src-pages-frequently-asked-questions-jsx": () => import("./../../../src/pages/frequently-asked-questions.jsx" /* webpackChunkName: "component---src-pages-frequently-asked-questions-jsx" */),
  "component---src-pages-hcp-aboutnpc-jsx": () => import("./../../../src/pages/hcp/aboutnpc.jsx" /* webpackChunkName: "component---src-pages-hcp-aboutnpc-jsx" */),
  "component---src-pages-hcp-index-jsx": () => import("./../../../src/pages/hcp/index.jsx" /* webpackChunkName: "component---src-pages-hcp-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jco-jsx": () => import("./../../../src/pages/jco.jsx" /* webpackChunkName: "component---src-pages-jco-jsx" */),
  "component---src-pages-join-npc-facts-community-jsx": () => import("./../../../src/pages/join-NPCFacts-community.jsx" /* webpackChunkName: "component---src-pages-join-npc-facts-community-jsx" */),
  "component---src-pages-learn-jsx": () => import("./../../../src/pages/learn.jsx" /* webpackChunkName: "component---src-pages-learn-jsx" */),
  "component---src-pages-mhncs-jsx": () => import("./../../../src/pages/mhncs.jsx" /* webpackChunkName: "component---src-pages-mhncs-jsx" */),
  "component---src-pages-nejm-jsx": () => import("./../../../src/pages/nejm.jsx" /* webpackChunkName: "component---src-pages-nejm-jsx" */),
  "component---src-pages-resources-jsx": () => import("./../../../src/pages/resources.jsx" /* webpackChunkName: "component---src-pages-resources-jsx" */),
  "component---src-pages-risk-factors-and-symptoms-jsx": () => import("./../../../src/pages/risk-factors-and-symptoms.jsx" /* webpackChunkName: "component---src-pages-risk-factors-and-symptoms-jsx" */),
  "component---src-pages-sitemap-jsx": () => import("./../../../src/pages/sitemap.jsx" /* webpackChunkName: "component---src-pages-sitemap-jsx" */),
  "component---src-pages-summer-jsx": () => import("./../../../src/pages/summer.jsx" /* webpackChunkName: "component---src-pages-summer-jsx" */)
}

